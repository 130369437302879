<template>
  <div class="pageWrapper">
    <div class="titleBar">
      基本信息
    </div>
    <div class="meetingDetailsBox">
      <div class="singleInfo">
        <span>会议主题：</span>
        <span>{{ meetingDetails.theme }}</span>
      </div>
      <div class="singleInfo">
        <span>会议室：</span>
        <span>{{ meetingDetails.roomName }}</span>
      </div>
      <div class="singleInfo">
        <span>会议等级：</span>
        <span>{{ meetingDetails.level | levelStatus }}</span>
      </div>
      <div class="singleInfo">
        <span>严重等级：</span>
        <span>{{ meetingDetails.severityLevel | importanceLevelStatus }}</span>
      </div>
      <div class="singleInfo">
        <span>运维工程师：</span>
        <span>{{ meetingDetails.operationNames }}</span>
      </div>
      <div class="singleInfo">
        <span>处理状态：</span>
        <span>{{ meetingDetails.dispose ? "已处理" : "未处理" }}</span>
      </div>
    </div>
    <div class="titleBar" v-if="meetingDetails.workorderScheduleVoList">
      工作进度
    </div>
    <div
      class="meetingDetailsBox"
      v-if="meetingDetails.workorderScheduleVoList"
    >
      <div
        class="singleNode"
        v-for="item in meetingDetails.workorderScheduleVoList"
        :key="item.scheduleId"
      >
        <div class="singleStatus">
          <span>{{ item.name + "：" }}</span>
          <span>{{ item.finish ? "已完成" : "待完成" }}</span>
        </div>
        <van-button
          @click="viewNode(item)"
          class="miniButton"
          plain
          type="primary"
          size="mini"
          >查看</van-button
        >
      </div>
    </div>

    <!-- <div class="titleBar" v-if="meetingDetails.workorderChangeList.length">
      转场记录
    </div>
    <div
      class="meetingDetailsBox"
      v-if="meetingDetails.workorderChangeList.length"
    >
      <div
        class="singleNode"
        v-for="item in meetingDetails.workorderChangeList"
        :key="item.id"
      >
        <div class="singleStatus">
          <span>{{ item.name + "：" }}</span>
          <span>{{ item.finish ? "已完成" : "待完成" }}</span>
        </div>
      </div>
    </div> -->

    <div class="titleBar">
      消息内容
    </div>
    <div class="meetingDetailsBox" style="min-height:100px">
      <div class="singleInfo">
        <span>{{ meetingDetails.content }}</span>
      </div>
    </div>

    <div class="bottomButtonWrapper">
      <van-button
        @click="handleViewWorkOrder"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >查看工单详情</van-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getMessageDetails } from "@/api/messageList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      meetingDetails: {},
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMessage() {
      return this.$store.state.currentMeeting.message;
    },
  },
  //监控data中的数据变化
  watch: {},
  filters: {
    levelStatus(level) {
      if (level == null || level == undefined) return "";
      let levelObj = {
        1: "VIP会议",
        2: "普通会议",
        3: "重要会议",
        4: "会议支持",
      };
      return `${levelObj[level]}`;
    },
    importanceLevelStatus(type) {
      if (type == null || type == undefined) return "";
      let typeObj = {
        1: "一级",
        2: "二级",
        3: "三级",
      };
      return `${typeObj[type]}`;
    },
  },
  //方法集合
  methods: {
    handleViewWorkOrder() {
      this.$store.commit("SetCurrentMeeting", this.meetingDetails);
      this.$store.commit("SetNavBarTitle", "工单信息");
      this.$router.push("/meetingDetails");
    },
    getCurrentMessageDetails() {
      let param = {
        messageId: this.currentMessage.messageId,
      };
      getMessageDetails(param).then((res) => {
        if (res.code === 10000) {
          this.meetingDetails = res.data;
          console.log(this.meetingDetails);
        } else {
          this.$notify({ type: "warning", message: res.message });
        }
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getCurrentMessageDetails();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
};
</script>
<style>
/* @import url(); 引入公共css类*/
.meetingDetailsBox {
  background-color: #fff;
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px;
}
.singleInfo {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
  align-items: center;
}
.singleInfo span,
div {
  font-size: 0.6rem;
  text-align: left;
  word-break: break-word;
}
.singleNode {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
}
.singleNode .miniButton .van-button__text {
  font-size: 0.3rem !important;
}
.miniButton {
  padding: 0 14px !important;
  height: 19px;
  border-radius: 3px;
  border: 1px solid #4bc2be !important;
  color: #4bc2be !important;
}
</style>
