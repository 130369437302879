import request from "@/utils/request";

export function getMessageList(param) {
  return request.get(`/mobile/admin/workorder/message/page`, {
    params: { ...param }
  });
}

export function getMessageDetails(param) {
  return request.get(`/mobile/admin/workorder/message/${param.messageId}`);
}
